<template>
  <div id="app">
    <b-modal
      id="mdlCaixaEntrada"
      ref="mdlCaixaEntrada"
      title="Escolher conta"
      ok-title="Salvar"
      ok-only
      @ok="salvarNumerosWhatsapp"
      size="lg"
    >
      <b-form
        v-if="selectedNumber"
        id="frmNumerosWhatsapp"
        ref="frmNumerosWhatsapp"
      >
        <b-row class="mb-1">
          <b-col cols="3">
            <b-form-input
              v-model="selectedNumber.phone_number"
              disabled
            ></b-form-input>
          </b-col>
          <b-col cols="5">
            <b-form-input
              v-model="selectedNumber.inbox_name"
              placeholder="Nome da caixa de entrada"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-select
              v-model="selectedNumber.conta_id"
              :options="accountOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="mdlExcluirNumero"
      ref="mdlExcluirNumero"
      title="Escolha o que deseja fazer"
      ok-title="Confirmar"
      :ok-disabled="
        !deleteNumberOptions.excluirCaixaEntrada &&
        !deleteNumberOptions.desvincularNumero
      "
      @ok="deleteNumber(selectedNumber, selectedWaba)"
      cancel-title="Cancelar"
      :centered="true"
    >
      <b-form id="frmDeleteNumber" ref="frmDeleteNumber">
        <b-row class="mb-1">
          <b-col>
            <b-form-checkbox
              v-model="deleteNumberOptions.excluirCaixaEntrada"
              style="margin-bottom: 10px"
              size="lg"
            >
              Excluir caixa de entrada
            </b-form-checkbox>
            <b-form-checkbox
              v-model="deleteNumberOptions.desvincularNumero"
              size="lg"
            >
              Desvincular número da conta
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="mdlGerenciarTemplates"
      ref="mdlGerenciarTemplates"
      title="Templates"
      :centered="true"
      hide-footer
    >
      <div class="table-numbers" style="margin-bottom: 20px">
        <table class="table table-hover">
          <thead>
            <tr class="text-center">
              <th style="width: 18%">Recurso</th>
              <th style="width: 20%">
                Templates
                <Tooltip
                  message="Templates padrão necessários para a utilização do recurso."
                />
              </th>
              <th style="width: 15%">Ações</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="recurso in recursosTemplates">
              <tr
                v-if="recursoAtivoConta(recurso.codigo)"
                :key="recurso.id"
                class="text-center"
              >
                <td>
                  <i :class="recurso.icone" class="mr-2"></i>
                  {{ recurso.nome }}
                </td>
                <td>
                  <i
                    v-if="recurso.templates"
                    class="fa-solid fa-check mr-2"
                    style="color: green"
                  ></i>
                  <i v-else class="fa-regular fa-circle mr-2"></i>
                </td>
                <td>
                  <b-button
                    variant="primary"
                    size="sm"
                    v-if="!recurso.templates"
                    @click="criarTemplates(recurso.id)"
                    >Criar templates</b-button
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-modal>
    <div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <h4 style="flex-grow: 1; text-align: center">Contas conectadas</h4>
        <div style="text-align: right">
          <b-button
            title="Recarregar contas"
            class="btn btn-icon btn-sm"
            @click="loadNumbersInformation()"
          >
            <i class="fa-solid fa-rotate-right" style="font-size: small"></i>
          </b-button>
        </div>
      </div>
      <div v-for="(account, i) in accounts" :key="i">
        <div style="align-items: center; justify-content: flex-end">
          <div style="padding-left: 4px">
            <h6
              for="filtro_ano"
              style="white-space: nowrap; margin-right: 10px"
            >
              {{ account.waba_name }}
            </h6>
          </div>
        </div>
        <div class="table-numbers" style="margin-bottom: 20px">
          <table class="table table-hover" v-if="numbers.length > 0">
            <thead>
              <tr class="text-center">
                <th style="width: 18%">
                  Número de telefone
                  <Tooltip
                    message="Número de telefone cadastrado na conta do WhatsApp Business."
                  />
                </th>
                <th style="width: 20%">
                  Nome
                  <Tooltip message="Nome verificado da linha." />
                </th>
                <th style="width: 20%">
                  Conta
                  <Tooltip message="Conta a qual esse número está associado." />
                </th>
                <th style="width: 10%">
                  Status
                  <Tooltip
                    message="Indica se o número está ou não registrado. É possível registrá-lo no menu de Ações."
                  />
                </th>
                <th style="width: 18%">
                  Caixa de entrada
                  <Tooltip
                    message="Indica se já existe uma caixa de entrada no Atende. É possível criá-la no menu de Ações."
                  />
                </th>
                <th style="width: 15%">
                  Ações
                  <Tooltip
                    message="Ações disponíveis para o número de telefone."
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="numero in account.numbers"
                :key="numero.phone_number"
                class="text-center"
              >
                <td>
                  {{ numero.phone_number }}
                </td>
                <td>{{ numero.name }}</td>
                <td>
                  <div
                    v-if="
                      accountOptions.find(
                        (option) => option.value === numero.conta_id
                      )
                    "
                  >
                    {{
                      accountOptions.find(
                        (option) => option.value === numero.conta_id
                      ).text
                    }}
                    <br />
                    {{
                      accountOptions.find(
                        (option) => option.value === numero.conta_id
                      ).name
                    }}
                  </div>
                </td>
                <td>
                  <div class="icones-container">
                    <span
                      class="label status-on label-inline mr-2"
                      v-if="numero.registered"
                      >Registrado</span
                    >
                    <span
                      v-else
                      class="label status-off label-inline mr-2"
                      style="width: 100px"
                      >Não registrado</span
                    >
                  </div>
                </td>
                <td>
                  <div class="icones-container">
                    <span
                      class="label status-on label-inline mr-2"
                      v-if="numero.inbox"
                      >Ativa</span
                    >
                    <span class="label status-off label-inline mr-2" v-else
                      >Inativa</span
                    >
                  </div>
                </td>
                <td>
                  <div class="icones-container">
                    <button
                      v-if="!numero.registered"
                      @click="register(numero, account.waba_id)"
                      class="btn btn-icon btn-light-primary btn-sm"
                      title="Registrar número"
                      style="margin-right: 10px"
                      :style="{
                        cursor: numero.registered ? 'not-allowed' : 'pointer'
                      }"
                    >
                      <i
                        class="fa-solid fa-clipboard-check"
                        style="font-size: small"
                      ></i>
                    </button>

                    <button
                      v-if="!(numero.inbox && numero.registered)"
                      class="btn btn-icon btn-light-primary btn-sm"
                      title="Criar caixa de entrada"
                      @click="createInbox(numero, account.waba_id)"
                      style="margin-right: 10px"
                      :style="{
                        cursor: numero.inbox ? 'not-allowed' : 'pointer'
                      }"
                    >
                      <i class="flaticon2-talk" style="font-size: small"></i>
                    </button>

                    <div v-else>
                      <button
                        class="btn btn-icon btn-light-primary btn-sm"
                        title="Remover número"
                        @click="selectOptions(numero, account.waba_id)"
                        style="margin-right: 10px"
                      >
                        <i class="flaticon2-trash" style="font-size: small"></i>
                      </button>
                      <button
                        class="btn btn-icon btn-light-primary btn-sm"
                        title="Gerenciar templates"
                        @click="
                          exibirModalGerenciarTemplates(numero, account.waba_id)
                        "
                      >
                        <i
                          class="fa-solid fa-list-check p-0"
                          style="font-size: small"
                        ></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Vue from "vue";
import "vuetify/dist/vuetify.css";
import Vuetify from "vuetify";
import Swal from "sweetalert2";
import Tooltip from "./dashboard/Tooltip.vue";
import Utils from "@/core/utils/Utils";

Vue.use(Vuetify);

export default {
  components: { Tooltip },
  data() {
    return {
      accountOptions: [],
      numbers: [],
      wabaOptions: [],
      selectedNumber: null,
      selectedWaba: null,
      accounts: [],
      deleteNumberOptions: {
        excluirCaixaEntrada: false,
        desvincularNumero: false
      },
      recursosTemplates: [
        {
          id: 9,
          nome: "Agende",
          templates: false,
          icone: "flaticon2-calendar",
          codigo: "AGENDAMENTO"
        },
        {
          id: 10,
          nome: "Atende",
          templates: false,
          icone: "flaticon2-talk",
          codigo: "LIVE_CHAT"
        },
        {
          id: 12,
          nome: "Opine",
          templates: false,
          icone: "flaticon2-pie-chart-1",
          codigo: "DIZVC"
        }
      ],
      recursosConta: [],
      ultimaContaSelecionada: null,
      templatesPadrao: []
    };
  },
  mounted() {
    this.inicializar();
  },
  computed: {
    ...mapGetters(["recursoAtivoConta"]),
    Utils() {
      return Utils;
    }
  },
  methods: {
    inicializar() {
      this.getWabas();
      this.getAccounts();
      this.getTemplatesPadrao();
    },
    createInbox(numero, waba_id) {
      this.selectedNumber = numero;
      this.selectedWaba = waba_id;
      this.$bvModal.show("mdlCaixaEntrada");
    },
    async getWabas() {
      await ApiService.get("whatsapp/wabas").then((response) => {
        this.wabaOptions = response.data.data;
      });
      if (this.wabaOptions.length > 0) {
        this.loadNumbersInformation();
      }
    },
    async getAccounts() {
      await ApiService.get("administrativo/conta").then((response) => {
        this.accountOptions = [];
        this.accountOptions.push({
          value: response.data.contaPrincipal.conta_id,
          text: response.data.contaPrincipal.con_login,
          name: response.data.contaPrincipal.con_nome_identificacao
        });
        response.data.subContas.forEach((element) => {
          this.accountOptions.push({
            value: element.conta_id,
            text: element.con_login,
            name: element.con_nome_identificacao
          });
        });
      });
    },
    async getTemplatesPadrao() {
      await ApiService.get("templates-padrao").then((response) => {
        this.templatesPadrao = response.data.data;
      });
    },
    async loadNumbersInformation() {
      this.accounts = [];
      this.wabaOptions.forEach(async (waba) => {
        await ApiService.get(
          "whatsapp/phone_numbers?waba_id=" + waba.waba_id
        ).then((response) => {
          this.numbers = [];
          response.data.data.forEach((element) => {
            let number = {
              name: element.name,
              number_id: element.number_id,
              phone_number: element.phone_number,
              conta_id: element.conta_id,
              registered: element.registered,
              inbox: element.inbox,
              inbox_name: null
            };
            this.numbers.push(number);
          });
        });

        this.accounts.push({
          waba_id: waba.waba_id,
          waba_name: waba.name,
          numbers: this.numbers
        });
      });
    },
    salvarNumerosWhatsapp() {
      this.$bvModal.hide("mdlCaixaEntrada");

      ApiService.post("whatsapp/create_inbox", {
        number: this.selectedNumber,
        waba_id: this.selectedWaba
      }).then(() => {
        Swal.fire({
          title: "SUCESSO",
          text: "Caixa de entrada criada com sucesso!",
          icon: "success",
          heightAuto: false
        }).catch(() => {
          Swal.fire({
            title: "ERRO",
            text: "Erro ao criar caixa de entrada!",
            icon: "error",
            heightAuto: false
          });
        });
      });
    },
    register(numero, waba_id) {
      ApiService.post("whatsapp/register", {
        number: numero,
        waba_id: waba_id
      })
        .then(() => {
          Swal.fire({
            title: "SUCESSO",
            text: "Número registrado com sucesso!",
            icon: "success",
            heightAuto: false
          });
          this.loadNumbersInformation();
        })
        .catch((error) => {
          Swal.fire({
            title: "ERRO",
            text: error.response.data.message,
            icon: "error",
            heightAuto: false
          });
        });
    },
    selectOptions(numero, waba_id) {
      this.selectedNumber = numero;
      this.selectedWaba = waba_id;

      this.deleteNumberOptions = {
        excluirCaixaEntrada: false,
        desvincularNumero: false
      };

      this.$bvModal.show("mdlExcluirNumero");
    },
    deleteNumber(numero, waba_id) {
      Swal.fire({
        title: "Atenção",
        text: "Deseja realmente fazer isso?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.$bvModal.hide("mdlExcluirNumero");

          if (this.deleteNumberOptions.excluirCaixaEntrada) {
            ApiService.delete("whatsapp/delete_inbox", {
              number: numero,
              waba_id: waba_id
            });
          }

          if (this.deleteNumberOptions.desvincularNumero) {
            ApiService.post("whatsapp/unlink_number", {
              number: numero,
              waba_id: waba_id
            });
          }

          this.deleteNumberOptions = {
            excluirCaixaEntrada: false,
            desvincularNumero: false
          };
          this.loadNumbersInformation();
        }
      });
    },
    async exibirModalGerenciarTemplates(numero) {
      this.selectedNumber = numero;
      if (this.ultimaContaSelecionada !== numero.conta_id) {
        this.ultimaContaSelecionada = numero.conta_id;

        let templatesConta = [];

        await ApiService.get("template?conta_id=" + numero.conta_id).then(
          (response) => {
            templatesConta = response.data.data[0].templates;
          }
        );

        // Verifica se todos os templates padrão estão na lista de templates da conta
        for (let recurso of this.recursosTemplates) {
          if (
            this.possuiTodosTemplates(
              recurso.id,
              this.templatesPadrao,
              templatesConta
            )
          ) {
            recurso.templates = true;
          }
        }
      }

      this.$bvModal.show("mdlGerenciarTemplates");
    },

    possuiTodosTemplates(recursoId, templatesPadrao, templatesConta) {
      // Obtem os templates padrão associados ao recursoId
      const templatesDoRecurso = templatesPadrao[recursoId];
      if (!templatesDoRecurso) {
        // Caso o recursoId não exista em templatesPadrao
        return false;
      }

      // Obtem os nomes dos templates da conta para facilitar a busca
      const nomesTemplatesConta = templatesConta.map(
        (template) => template.name
      );

      // Verifica se todos os templates padrão estão na lista de templates da conta
      return templatesDoRecurso.every((template) =>
        nomesTemplatesConta.includes(template.name)
      );
    },

    criarTemplates(recursoId) {
      ApiService.post("templates-padrao", {
        conta_id: this.selectedNumber.conta_id,
        recurso_id: recursoId
      })
        .then(() => {
          Swal.fire({
            title: "SUCESSO",
            text: "Templates criados com sucesso!",
            icon: "success",
            heightAuto: false
          });
          this.recursosTemplates.find(
            (recurso) => recurso.id === recursoId
          ).templates = true;
        })
        .catch(() => {
          Swal.fire({
            title: "ERRO",
            text: "Erro ao criar templates!",
            icon: "error",
            heightAuto: false
          });
        });
    }
  }
};
</script>

<style>
.status-on {
  background-color: rgba(97, 191, 3, 0.308);
  border-radius: 90%;
  width: 9px;
  height: 20px;
  color: green;
}
.status-off {
  background-color: rgb(214, 21, 21, 0.308);
  border-radius: 90%;
  width: 9px;
  height: 20px;
  color: red;
}

.table-numbers {
  width: 100%;
  border-top: 1.5px solid #e0e0e0;
  border-left: 1.5px solid #e0e0e0;
  border-right: 1.5px solid #e0e0e0;
  border-bottom: 1.5px solid #e0e0e0;
  border-radius: 10px;
  border-collapse: separate;
  table-layout: fixed;
}

.table-numbers td {
  vertical-align: middle;
}
</style>
